import React, { useEffect, useState, Suspense, lazy } from "react";
import { ChakraProvider, Flex, Box, Spinner } from "@chakra-ui/react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
// Components that aren't lazily loaded
import Sidebar from "./components/sideBar";
import Navbar from "./components/navBar";
import DefaultLayoutProvider from "./layout/defalutLayout";
import SlotUploader from "./screens/slots/slotsCsv";

// Lazy load components
const OrderTable = lazy(() => import("./screens/OrderList/OrderListScreen"));
const Dashboard = lazy(() => import("./screens/Dashboard/DashboardScreen"));
const LeadsTable = lazy(() => import("./screens/Leads/LeadsScreen"));
const ProductsCategory = lazy(() =>
  import("./screens/Category/CategoryScreen")
);
const NotFoundPage = lazy(() => import("./components/NotFound"));

const ProductsPage = lazy(() => import("./screens/Product/ProductScreen"));
const InvoicePage = lazy(() => import("./screens/Invoice/InvoiceScreen"));
const TestTable = lazy(() => import("./screens/TestTable/TestTableScreen"));
const CardTable = lazy(() => import("./screens/CardTable/CardTableScreen"));
const CourseTable = lazy(() =>
  import("./screens/CourseTable/CourseTableScreen")
);
const FormComponent = lazy(() =>
  import("./screens/CartOrderScreen/AddItemsScreen")
);
const AddToCartPage = lazy(() =>
  import("./screens/CartOrderScreen/AddItemsScreen")
);
const LoginPage = lazy(() => import("./screens/LoginScreen/LoginScreen"));
const NotLoggedInPage = lazy(() =>
  import("./screens/NotLoggedInScreen/NotLoggedInScreen")
);
const UserPage = lazy(() => import("./screens/UserScreen.jsx/UserScreen"));
const SettingsPage = lazy(() =>
  import("./screens/SettingsScreen/SettingsScreen")
);
const AdminManagement = lazy(() => import("./screens/AdminScreen/AdminScreen"));
const UserProfilePage = lazy(() =>
  import("./screens/ProfileScreen/ProfileScreen")
);
const OrderSummaryScreen = lazy(() =>
  import("./screens/OrderSummaryScreen/OrderSummaryScreen")
);
const SuccessPayment = lazy(() =>
  import("./screens/PaymentStatus/SuccessPayment")
);
const FailedPayment = lazy(() =>
  import("./screens/PaymentStatus/FailedPayment")
);

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("auth");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [navigate]);

  return (
    <div>
      <DefaultLayoutProvider>
        <Suspense
          fallback={
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
              position="fixed"
              left="50%"
              top="50%"
            />
          }>
          {isLoggedIn ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />}
        </Suspense>
      </DefaultLayoutProvider>
    </div>
  );
};

const AuthenticatedRoutes = () => (
  <Flex direction="column" h="100vh">
    <Navbar />
    <Flex flex="1">
      <Sidebar />
      <Box flex="1" ml={{ base: 0, md: 60 }} p={5} mt={0}>
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          <Route path="/not-verified" element={<NotLoggedInPage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/order-table" element={<OrderTable />} />
          <Route path="/leads-table" element={<LeadsTable />} />
          <Route path="/product-category" element={<ProductsCategory />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/invoice" element={<InvoicePage />} />
          <Route path="/user-list" element={<UserPage />} />
          <Route path="/test-table" element={<TestTable />} />
          <Route path="/card-table" element={<CardTable />} />
          <Route path="/admin-list" element={<AdminManagement />} />
          <Route path="/course-table" element={<CourseTable />} />
          <Route path="/form" element={<FormComponent />} />
          <Route path="/add-to-cart/:id" element={<AddToCartPage />} />
          <Route path="/add-to-cart" element={<AddToCartPage />} />
          <Route path="/website-settings" element={<SettingsPage />} />
          <Route path="/user-profile" element={<UserProfilePage />} />
          <Route path="/success" element={<SuccessPayment />} />
          <Route path="/cancel" element={<FailedPayment />} />
          <Route path="/slots-creation" element={<SlotUploader />} />
          <Route path="/order-summary/:id" element={<OrderSummaryScreen />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Box>
    </Flex>
  </Flex>
);

const UnauthenticatedRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/login" replace />} />
    <Route path="/login" element={<LoginPage />} />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

export default App;
