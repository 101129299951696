import React, { useState } from "react";
import Papa from "papaparse";
import {
  Box,
  Input,
  Text,
  VStack,
  Alert,
  AlertIcon,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Select,
  Button,
} from "@chakra-ui/react";
import axios from "axios";

const SlotUploader = ({ onSlotsAdded }) => {
  const [slots, setSlots] = useState([]);
  const [error, setError] = useState("");
  const [file, setFile] = useState(null);

  const saveSlotsToDB = async () => {
    if (!file) {
      console.error("No file selected.");
      setError("Please upload a file before saving.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post("/slots/create-bulk-slots", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.success) {
        console.log("CSV Data successfully saved:", response.data.result);
      } else {
        console.error("Error saving data:", response.data.message);
        setError(response.data.message);
      }
    } catch (error) {
      console.error("Upload failed:", error);
      setError("An error occurred while saving the data.");
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file && file.type === "text/csv") {
      setError("");
      setFile(file);
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (result) {
          if (result.errors.length === 0) {
            const parsedData = result.data.map((row) => ({
              mode: row["Mode"],
              title: row["Title"],
              startDate: row["Start Date"],
              endDate: row["End Date"],
              product: row["Product"],
              scheduleDays: row["Schedule Days"],
              scheduleDates: row["Schedule Dates"],
              seatNo: row["Seat No"],
            }));

            setSlots(parsedData);
            if (onSlotsAdded) onSlotsAdded(parsedData);
          } else {
            setError("Error parsing CSV file. Please check the format.");
          }
        },
      });
    } else {
      setError("Please upload a valid CSV file.");
    }
  };

  return (
    <Box
      padding={8}
      bg="gray.50"
      marginTop={"80px"}
      borderRadius="md"
      shadow="md"
    >
      <Text fontSize="lg" fontWeight="bold" mb={4}>
        Upload CSV File
      </Text>

      <Input
        type="file"
        accept=".csv"
        onChange={handleFileUpload}
        mb={4}
        bg="white"
        borderColor="gray.300"
      />

      {error && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          {error}
        </Alert>
      )}

      <Button
        backgroundColor={"#FF3131"}
        textColor={"white"}
        marginBottom={"4px"}
        onClick={() => saveSlotsToDB(slots)}
      >
        Save data
      </Button>

      <VStack align="start" spacing={4} w="100%">
        <Text fontWeight="bold">Parsed Slots:</Text>
        {slots.length > 0 ? (
          <TableContainer w="100%">
            <Table variant="striped" colorScheme="gray">
              <Thead>
                <Tr>
                  <Th>Mode</Th>
                  <Th>Title</Th>
                  <Th>Start Date</Th>
                  <Th>End Date</Th>
                  <Th>Product</Th>
                  <Th>Schedule Days</Th>
                  <Th>Schedule Dates</Th>
                  <Th>Seat No</Th>
                </Tr>
              </Thead>
              <Tbody>
                {slots.map((slot, index) => (
                  <Tr key={index}>
                    <Td>{slot.mode}</Td>
                    <Td>{slot.title}</Td>
                    <Td>{slot.startDate}</Td>
                    <Td>{slot.endDate}</Td>
                    <Td>{slot.product}</Td>
                    <Td>{slot.scheduleDays}</Td>
                    <Td>
                      {slot.scheduleDates.split(",").map((value) => (
                        <p>{value}</p>
                      ))}{" "}
                    </Td>
                    <Td>{slot.seatNo}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <Box>No slots available. Please upload a CSV file.</Box>
        )}
      </VStack>
    </Box>
  );
};

export default SlotUploader;
